import React, { useState } from "react";
import { Button, DatePicker, Modal, Radio, Space } from "antd";
import { ModalProps } from "antd/es/modal/interface";
import { Device } from "../../models/device.entity";
import dayjs, { Dayjs } from "dayjs";
import { deviceApi } from "../../api/device.api";


declare type EventValue<DateType> = DateType | null;
declare type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;


const { RangePicker } = DatePicker;

type DeviceDataDownloadModalProps = Pick<ModalProps, "onCancel" | "open"> & {
  device: Device;
};

type FieldType = {
  startTime: Dayjs;
  endTime: Dayjs;
  uuid: string;
};

type DateButtonValue = "lastDay" | "lastWeek" | "lastMonth" | "lastYear";
const LastDay: DateButtonValue = "lastDay";
const lastWeek: DateButtonValue = "lastWeek";
const lastMonth: DateButtonValue = "lastMonth";
const lastYear: DateButtonValue = "lastYear";

const DeviceDataDownloadModal = (props: DeviceDataDownloadModalProps) => {
  const [field, setField] = useState<FieldType>({
    startTime: dayjs(),
    endTime: dayjs(),
    uuid: props.device.uuid,
  });

  // State for selected button value
  const [selectedValue, setSelectedValue] = useState<DateButtonValue | null>(null);


  const onRangeChange = (dates: RangeValue<Dayjs>) => {
    if (dates) {
      setField({
        ...field,
        startTime: dates[0] || dayjs(),
        endTime: dates[1] || dayjs(),
      });
    }
  };


  const onClickButton = (value: DateButtonValue) => {
    let startTime: Dayjs = dayjs();
    let endTime: Dayjs = dayjs();

    switch (value) {
      case LastDay:
        startTime = dayjs().subtract(1, "day");
        endTime = dayjs();
        break;
      case lastWeek:
        startTime = dayjs().subtract(1, "week");
        endTime = dayjs();
        break;
      case lastMonth:
        startTime = dayjs().subtract(1, "month");
        endTime = dayjs();
        break;
      case lastYear:
        startTime = dayjs().subtract(1, "year");
        endTime = dayjs();
        break;
      default:
        return;
    }

    setField({
      uuid: field.uuid,
      startTime: startTime,
      endTime: endTime,
    });

    setSelectedValue(value);
  };

  const onClickDownload = async () => {
    deviceApi.getDownloadDeviceData(field.uuid, "dispenser", field.startTime.valueOf(), field.endTime.valueOf());
  };

  return (
    <Modal
      title={`Download Device ${props.device.name} Data`}
      open={props.open}
      onCancel={props.onCancel}
      footer={null}
    >
      <br />
      <Space direction={"vertical"} size={"large"} style={{ width: "100%" }}>
        <Radio.Group value={selectedValue} onChange={e => onClickButton(e.target.value)} style={{ width: "100%" }}>
          {[LastDay, lastWeek, lastMonth, lastYear].map(value => (
            <Radio.Button key={value} value={value} onClick={() => {
              if (value === selectedValue) {
                onClickButton(value);  // This re-triggers the button action even if already selected
              }
            }}>
              {value.replace(/^(.)/, match => match.toUpperCase() + match.slice(1))}
            </Radio.Button>
          ))}
        </Radio.Group>

        <RangePicker
          value={[dayjs(field.startTime), dayjs(field.endTime)]}
          style={{ width: "100%" }}
          onChange={onRangeChange}
        />

        <Button type={"primary"} block onClick={onClickDownload}>
          Download
        </Button>
      </Space>
    </Modal>
  );
};

export default DeviceDataDownloadModal;
